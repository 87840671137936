let baseUrl = 'https://lafontaine-bxl-api.a4c.be/';
export const environment = {
  defaultSoftwareName: "La Fontaine",
  softwareName: "La Fontaine",
  production: true,
  baseUrl: baseUrl,
  apiUrl: baseUrl + "api",
  apiDoc: baseUrl + "doc",
  apiUp: baseUrl + "up",
  mainColor: "blue",
  defaultMainColor: "blue"
};
